<template>
  <div class="footer">
    <div class="container" style="padding: 0;">
      <div class="footer-content row">
        <!-- Left Side -->
        <div class="footer-left col-md-5 col-md-12">
          <div class="logo">
            <img src="../assets/logo2.png" alt="Logo" class="img-fluid">
          </div>
          <div class="text">
            <h2 style="color:white; font-size: 13px;">Send it offers reliable and trouble-free <br> worldwide shipping to
              over 220+ worldwide<br> destinations.</h2>
          </div>
          <!-- <div class="social">
            <img src="../assets/social.png" alt="Visa" class="img-fluid">
          </div> -->
          <h2 class="quick-links-heading">Company</h2>
          <nav class="footer-nav">
            <a :href="marketingSiteURL + 'about-us'">About Us</a>
            <a :href="marketingSiteURL + 'contact-us'">Contact Us</a>
            <!-- <a :href="marketingSiteURL + 'book'">Book</a> -->
            <a href="/get-a-quote">Book</a>
          </nav>
          <div class="social">
            <a href="https://www.facebook.com/Sendituae/">
              <img src="../assets/f_social.png" alt="facebook" class="img-fluid-social">
            </a>

            <a href="https://ae.linkedin.com/company/senditworld">
              <img src="../assets/l_social.png" alt="linkedin" class="img-fluid-social">
            </a>

            <a href="https://www.instagram.com/sendituae/?hl=en">
              <img src="../assets/i_social.png" alt="instagram" class="img-fluid-social">
            </a>
          </div>
          <!-- <div class="payment-methods mt-4">
            <img src="../assets/payment.png" alt="Visa" class="img-fluid"> -->
            <!-- <img src="../assets/mastercard.svg" alt="MasterCard" class="img-fluid">
            <img src="../assets/unionpay.svg" alt="UnionPay" class="img-fluid"> -->
          <!-- </div> -->
        </div>

        <!-- Center -->
        <div class="footer-center col-md-3">
          <h2 class="quick-links-heading">Quick Links</h2>
          <nav class="footer-nav">
            <a :href="marketingSiteURL + 'passport-shipping'">Passport Shipping</a>
            <a :href="marketingSiteURL + 'luggage-shipping'">Luggage Shipping</a>
            <a :href="marketingSiteURL + 'document-shipping'">Document Shipping</a>
            <a :href="marketingSiteURL + 'box-shipping'">Box Shipping</a>
            <a :href="marketingSiteURL + 'pallet-shipping'">Pallet Shipping</a>
            <a :href="marketingSiteURL + 'sports-equipment-shipping'">Sports Equipment Shipping</a>
            <a :href="marketingSiteURL + 'bike-shipping'">Bike Shipping</a>
            <a :href="marketingSiteURL + 'relocation-services'">Relocation Services</a>
          </nav>
        </div>

        <!-- Right Side -->
        <div class="footer-right col-md-4">
          <h2 class="quick-links-heading">Resources</h2>
          <nav class="footer-nav">
            <a :href="marketingSiteURL + 'blog'">Blog</a>
            <a :href="marketingSiteURL + 'prohibited-items'">Prohibited Items</a>
          </nav>
          <div class="subscribe-newsletter">
            <p style="display:flex;"><img src="../assets/vector-mail.png" alt="email" class="img-fluid d-none d-md-block" style=" margin-right: 10px; margin-bottom: 2px;"> Subscribe for News letter</p>
            <form @submit.prevent="subscribe">
              <input type="email" placeholder="Enter Your Email" v-model="email" required class="form-control">
              <div class="subscribe-submit-button">
              <button type="submit" class="btn btn-primary subscribe">Submit</button>
              </div>
            </form>
            <img src="../assets/visa-unionpay1.svg" alt="Visa" class="img-fluid visa-image">
          </div>
        </div>
      </div>
    </div>

    <div class="tracking-n-chat">
      <div class="container">
        <div class="inner-wrapper row">
          <!-- <TrackingForm class="col-md-6"/> -->
          <div class="chat-wrapper col-md-6">
            <!-- <a href="#" class="chat float-right"></a> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="footer-bottom-content d-flex justify-content-between align-items-center">
        <p>&copy; 2024 Your Company Name. All rights reserved.</p>
        <div class="footer-links">
          <a :href="marketingSiteURL + 'terms-conditions'">Terms and Conditions</a>
          <a :href="marketingSiteURL + 'privacy-policy'">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
//import TrackingForm from '@/components/TrackingForm.vue';

export default {
  name: 'Footer',
  components: {
    //TrackingForm,
  },
  data() {
    return {
      marketingSiteURL: process.env.VUE_APP_MARKETING_SITE_URL,
      email: '',
    };
  },
  mounted() {
    this.stickyTrackingNChat();
    window.addEventListener('scroll', this.debounce(this.stickyTrackingNChat, 100));
  },
  methods: {
    stickyTrackingNChat() {
      if (window.innerWidth <= 640) {
        if (document.documentElement.scrollTop >= 100) {
          document.querySelector('.tracking-n-chat').classList.add('show');
        } else {
          if (!document.querySelector('.tracking-form').classList.contains('success')) {
            document.querySelector('.tracking-n-chat').classList.remove('show');
          }
        }
      } else {
        document.querySelector('.tracking-n-chat').classList.add('show');
      }
    },
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    subscribe() {
      // Handle subscription logic here
      alert(`Subscribed with email: ${this.email}`);
    }
  },
};
</script>

<style scoped>
.footer {
  margin-top: 0px !important;
  padding: 100px 20px;
  background-color: #1985A1;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left,
.footer-center,
.footer-right {
  flex: 1;
  padding: 0 10px;
}

.footer-left {
  text-align: left;
}

.footer-left .logo img {
  max-width: 105px;
  margin-bottom: 12px;
}

.footer-left .text {
  margin: 10px 0;
  color: rgb(255, 255, 255);
}

.footer-left .payment-methods img {
  margin-right: 10px;
}

.footer-left .social img {
  margin-right: 10px;
}

.footer-center {
  text-align: left;
}

.quick-links-heading {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: rgb(255, 255, 255); /* Black font for the heading */
  font-weight: 600;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 12px 0px 0px 0px;
}

.footer-nav a {
  margin-bottom: 18px;
  text-decoration: none;
  color: rgb(255, 255, 255); /* Black font color */
  font-size: 13px;
  font-family: "Carmen", Sans-serif;
}

.footer-right {
  text-align: left;
}

.subscribe-newsletter p {
  margin-bottom: 10px;
  color: rgb(255, 255, 255); /* Black font color */
  font-size: 13px;
  align-items: center;
}

.subscribe-newsletter form {
  display: flex;
  /* flex-direction: column; */
  max-width: 280px;
  border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ccc;
}

.subscribe-newsletter input {
  height: 45px;
  padding: 6px 16px;
  /* margin-bottom: 25px; */
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 0px;
    border: none;
    color: #444444;
}

.subscribe-newsletter input::-webkit-input-placeholder{
    color: black;
}

.subscribe-submit-button {
    background-color: white;
    display: flex;
    padding: 4px;
}

button.btn.btn-primary.subscribe {
  /* background-image: linear-gradient(to right, #1985A1 0%, #024455 100%); */
  /* position: absolute;
  right: 21px;
  bottom: 129px; */
      background-color: #1985A1;
    border-radius: 0px !important;
}

.footer-bottom {
  background-color: #4C5C68;
  padding: 18px 0;
}

.footer-bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: rgb(247, 247, 247); /* Black font color */
}

.footer-bottom .footer-links {
  display: flex;
  gap: 10px; /* Space between links */
}

.footer-bottom .footer-links a {
  text-decoration: none;
  color: rgb(255, 255, 255); /* Black font color */
  font-size: 14px;
}

/* Media Queries for Mobile and Tablets */
@media (max-width: 991px) { /* Tablets */
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    padding: 10px 0;
  }

  button.btn.btn-primary.subscribe {
    /* background-image: linear-gradient(to right, #1985A1 0%, #024455 100%); */
    position: absolute;
    right: 14px;
    top: 206px;
    height: 30px;
        background-color: #1985A1;
    border-radius: 0px !important;
  }
}

@media (max-width: 767px) { /* Mobile */
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    width: 100%;
    padding: 10px 40px;
  }

  button.btn.btn-primary.subscribe {
    /* background-image: linear-gradient(to right, #1985A1 0%, #024455 100%); */
    background-color: #1985A1;
    position: absolute;
    right: 50px;
    top: 205px;
    height: 31px;
    border-radius: 0px !important;
  }

  .footer-bottom .footer-links {
    margin-top: 10px; /* Add margin to separate links from text */
  }
}

img.img-fluid-social:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease-out;
}
.footer .footer-content.row .text h2{
    line-height: 26px;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 14px !important;
    margin-bottom: 20px;
    font-family: "Carmen", Sans-serif;
}

.visa-image{
      max-height: 60px;
    margin-top: 20px;
}
</style>