import { createRouter, createWebHistory } from 'vue-router';
//import Home from '../views/Home.vue';
import VueBodyClass from 'vue-body-class';
import store from '../store';
//import Vue from 'vue';
import { nextTick } from 'vue';

const routes = [
    {
        path: '/',
        redirect: () => {
            return store.getters.loggedIn ? { name: 'Addresses' } : { name: 'Login' };
        },
    },
    {
        path: '/login',
        name: 'Login',
        alias: '/',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
        meta: { title: 'Login', bodyClass: 'entry-page', requiresVisitor: true, closeMenu: true },
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPasswordView.vue'),
        meta: { title: 'Reset Password', bodyClass: 'entry-page', requiresVisitor: true },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "logout" */ '../components/auth/Logout.vue'),
        meta: { title: 'Logout', bodyClass: 'logout-page', requiresAuth: true },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
        meta: { title: 'Register', bodyClass: 'entry-page', requiresVisitor: true },
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/OrdersView.vue'),
        meta: { title: 'Orders', bodyClass: 'orders-page', requiresAuth: true, closeMenu: true },
    },
    {
        path: '/order/:id',
        name: 'Order',
        component: () => import(/* webpackChunkName: "order" */ '../views/OrderView.vue'),
        meta: { title: 'Order Detail', bodyClass: 'order-page', requiresAuth: true },
    },
    {
        path: '/order-confirmation',
        name: 'Order Confirmation',
        component: () => import(/* webpackChunkName: "order-confirmation" */ '../views/OrderConfirmationView.vue'),
        meta: { title: 'Order Confirmation', bodyClass: 'order-confirmation-page', requiresAuth: true },
    },
    {
        path: '/order-failure',
        name: 'Order Failure',
        component: () => import(/* webpackChunkName: "order-failure" */ '../views/OrderFailureView.vue'),
        meta: { title: 'Order Failure', bodyClass: 'order-failure-page', requiresAuth: true },
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: () => import(/* webpackChunkName: "order-confirmation" */ '../views/TransactionsView.vue'),
        meta: { title: 'Transactions', bodyClass: 'transactions-page', requiresAuth: true, closeMenu: true },
    },
    {
        path: '/quote',
        name: 'Quote',
        component: () => import(/* webpackChunkName: "booking" */ '../views/QuoteView.vue'),
        meta: { title: 'Quote', bodyClass: 'quote-page', requiresAuth: false },
    },
    {
        path: '/get-a-quote',
        name: 'Get a Quote',
        component: () => import(/* webpackChunkName: "booking" */ '../views/GetQuote.vue'),
        meta: { title: 'Get a Quote', bodyClass: 'quote-page', requiresAuth: true },
    },
    {
        path: '/booking',
        name: 'Booking',
        component: () => import(/* webpackChunkName: "booking" */ '../views/BookingView.vue'),
        meta: { title: 'Booking', bodyClass: 'booking-page', requiresAuth: true },
    },
    {
        path: '/addresses',
        name: 'Addresses',
        component: () => import(/* webpackChunkName: "addresses" */ '../views/AddressesView.vue'),
        meta: { title: 'Addresses', bodyClass: 'addresses-page', requiresAuth: true, closeMenu: true },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
        meta: { title: 'Settings', bodyClass: 'settings-page', requiresAuth: true, closeMenu: true },
    },
    /* {
    path: '/is-logged-in',
    name: 'IsLoggedIn',
    component: () => import('../views/IsLoggedIn.vue'),
    meta: { bodyClass: 'is-logged-in-page' },
  }, */
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFoundView.vue'),
        meta: { title: 'Not Found', bodyClass: 'not-found-page', requiresVisitor: true, closeMenu: true },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const vueBodyClass = new VueBodyClass(routes);

router.beforeEach((to, from, next) => {
    if (to.meta.closeMenu != null && to.meta.closeMenu) {
        window.dispatchEvent(new CustomEvent('toggleMenu', { detail: { open: false } }));
    }

    /* ---------------------------- */

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            // Redirect to Login if not authenticated
            store.dispatch('setAfterLoginRedirect', { path: to.path, query: to.query });
            next({ name: 'Login' });
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        // Redirect to Addresses if authenticated but accessing visitor-only page
        if (store.getters.loggedIn) {
            next({ name: 'Addresses' });
        } else {
            next();
        }
    } else {
        next(); // Continue as normal
    }
});

router.beforeEach((to, from, next) => {
    vueBodyClass.guard(to, next);
});

const DEFAULT_TITLE = 'Home';
router.afterEach((to) => {
    nextTick(() => {
        document.title = 'Sendit - ' + (to.meta.title || DEFAULT_TITLE);
    });
});

export default router;
