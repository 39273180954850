<template>
  <div class="header user-login" x-data="header" :class="{ 'menu-open': menu.open }">
    <!-- <div class="top-note">Send it - The UAE’s #1 Shipping Comparison Site</div> -->
    <div class="top-nav-wrapper">
      <div class="container xl">
        <nav class="main-nav">
          <div class="logo-wrapper">
            <a :href="marketingSiteUrl" class="logo">
              <img src="../assets/header-logo.png" alt="" />
            </a>
            <a href="#" class="hamburger" @click.prevent="toggleMenu()"></a>
          </div>

          <div class="main-links-wrapper">
           <!-- 
            <div class="main-links">
              <a :href="marketingSiteUrl + 'about-us'">About</a>
              <a :href="marketingSiteUrl + 'destination'">Our Destinations</a>
              <a :href="marketingSiteUrl + 'our-services'">Our Services</a>
              <a :href="marketingSiteUrl + 'faq'">FAQs</a>
              <a :href="marketingSiteUrl + 'contact'">Contact</a>
            </div>
            -->

            <div class="main-links-nav" style="margin-left: 10px !important; text-align: center;">
              <div class="dropdown">
                <a href="#" class="dropdown-toggle">Our Services</a>
                <ul class="dropdown-menu">
                  <!-- <li><a href="https://staging.senditworld.com/passport-shipping/">Passport Shipping</a></li>
                  <li><a href="https://staging.senditworld.com/luggage-shipping/">Luggage Shipping</a></li>
                  <li><a href="https://staging.senditworld.com/document-shipping/">Document Shipping</a></li>
                  <li><a href="https://staging.senditworld.com/box-shipping/">Box Shipping</a></li>
                  <li><a href="https://staging.senditworld.com/pallet-shipping/">Pallet Shipping</a></li>
                  <li><a href="https://staging.senditworld.com/sports-equipment-shipping/">Sports Equipment Shipping</a></li>
                  <li><a href="https://staging.senditworld.com/bike-shipping/">Bike Shipping</a></li>
                  <li><a href="https://staging.senditworld.com/relocation-services/">Relocation Services</a></li> -->
                  <li><a :href="baseURL + 'passport-shipping'">Passport Shipping</a></li>
                  <li><a :href="baseURL + 'luggage-shipping'">Luggage Shipping</a></li>
                  <li><a :href="baseURL + 'document-shipping'">Document Shipping</a></li>
                  <li><a :href="baseURL + 'box-shipping'">Box Shipping</a></li>
                  <li><a :href="baseURL + 'pallet-shipping'">Pallet Shipping</a></li>
                  <li><a :href="baseURL + 'sports-equipment-shipping'">Sports Equipment Shipping</a></li>
                  <li><a :href="baseURL + 'bike-shipping'">Bike Shipping</a></li>
                  <li><a :href="baseURL + 'relocation-services'">Relocation Services</a></li>
                </ul>
              </div>
              <a :href="marketingSiteUrl + 'about-us-sendit'">About Us</a>
              <div class="dropdown">
                <a href="#" class="dropdown-toggle">Resources</a>
                <ul class="dropdown-menu">
                  <li><a :href="baseURL + 'faq'">FAQs</a></li>
                  <li><a :href="baseURL + 'blog'">Blog</a></li>
                </ul>
              </div>
              <a :href="marketingSiteUrl + 'contact'">Contact Us</a>
            </div>

            <div class="main-links ">
              <router-link to="/get-a-quote" @click="handleClick" class="header-quote">Get a Quote</router-link>
              <!-- <button type="submit" class="btn btn-dark">Get a Qoute</button> -->
              <div class="input-field-wrapper phone-input-field-wrapper"></div>
              <!-- <div class="country-code-dropdown">
                <select
                    name="countryCode"
                    placeholder="Search"
                    ref="countryCodeDropdownEl"
                    data-rules='["required"]'
                    v-model="countryCode"
                ></select>
              </div> -->
            </div>


            <div class="account">
              <div v-if="!$store.getters.loggedIn" style="width: 75%; display: flex; justify-content: space-evenly;">
                <!-- <router-link to="/login" class="trigger">Account</router-link> -->
                <router-link to="/register" class="btn header-right-button">Register</router-link>
                <router-link to="/login" class="btn header-right-button">Login</router-link>
              </div>

              <!-- <div v-if="$store.getters.loggedIn">
                <router-link to="/settings" class="has-name trigger">
                <div class="dp" :style="{ 'background-image': 'url(' + $store.state.user.profileImage + ')' }"></div>
                <div class="name">{{ $store.state.user.firstName }}</div>
                </router-link>
              </div> -->

              <div v-if="$store.getters.loggedIn">
                <a href="#" class="trigger has-dp" style="background-color: transparent;">
                  <div class="profilenav" style="display: flex; align-items: center;">
                    <div class="dp" :style="{ 'background-image': $store.state.user.profileImage != null ? 'url(' + $store.state.user.profileImage + ')':'' }"></div>
                    <p class="user-name" style="margin-left: 10px; font-size: 13px;"><span style="color: #1985a1; font-weight: bold;">{{ $store.state.user.firstName }}  {{ $store.state.user.lastName }}</span><br> 
                      <!-- <span style="color: #989898;">{{ $store.state.user.email }}</span> -->
                    </p>
                    <!-- <p class="user-email">{{ $store.state.user.email }}</p> -->
                  </div>
                </a>

                <div class="dropdown">
                  <div class="inner-content">
                    <ul>
                      <li>
                        <router-link class="header-nav" to="/orders"><i class="fa fa-cube"></i> Orders</router-link>
                      </li>
                      <li>
                        <router-link class="header-nav" to="/addresses"><i class="fa fa-map-marker"></i> Addresses</router-link>
                      </li>
                      <li>
                        <router-link class="header-nav" to="/logout"><i class="fa fa-sign-out"></i> Logout</router-link>
                        <!-- <router-link class="transactions" to="/transactions">Transaction</router-link> -->
                         <!-- <router-link class="settings" to="/settings">Settings</router-link> -->
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
/* global disableBodyScrolling, enableBodyScrolling */
import * as TomSelect from "tom-select";
export default {
  name: 'Header',
  data() {
    return {
      marketingSiteUrl: process.env.VUE_APP_MARKETING_SITE_URL,
      baseURL: process.env.VUE_APP_USER_WEBSITE_URL || 'https://senditworld.com/',
      menu: {
        open: false,
      },
    };
  },
  mounted() {

    window.addEventListener('toggleMenu', (e) => {
      this.toggleMenu(e.detail.open);
    });
    this.$store.dispatch("fetchCountries").then(() => {
        this.initCountryCodeDropdown();
    });

  },
  methods: {
    handleClick(event) {
      event.preventDefault(); // Prevent default Vue Router navigation
      window.location.href = '/get-a-quote'; // Perform a full page reload
    },
    toggleMenu(open) {
      open = typeof open != 'undefined' ? open : !this.menu.open;
      this.menu.open = open;
      
      if (this.menu.open) {  
        setTimeout(() => {
          disableBodyScrolling();
        });
      } else {
        enableBodyScrolling();
      }
    },
    initCountryCodeDropdown() {
    const countries = JSON.parse(JSON.stringify(this.$store.state.countries));
    const flagsBaseUrl = this.$store.state.flagsBaseUrl;

    const defaultValue = "AE"; // Set a default value based on short name or code
    this.countryCode = defaultValue;

    new TomSelect(this.$refs.countryCodeDropdownEl, {
    valueField: "code",
    searchField: ["name", "dialCode", "shortName",],
    options: countries,
    maxOptions: 300,
    render: {
        option: function (data, escape) {
            return (
                '<div class="country-code">' +
                '<div class="flag-wrapper">' +
                '<div class="flag" style="background-image: url(\'' +
                flagsBaseUrl +
                data.flag +
                "')\"></div>" +
                "</div>" +
                '<div class="name">' +
                escape(data.shortName || data.name) + // Fallback to name if shortName is undefined
                "</div>" +
                "</div>"
            );
        },
        item: function (data, escape) {
            return (
                '<div class="country-code">' +
                '<div class="flag-wrapper">' +
                '<div class="flag" style="background-image: url(\'' +
                flagsBaseUrl +
                data.flag +
                "')\"></div>" +
                "</div>" +
                '<div class="name">' +
                escape(data.shortName || data.name) + // Fallback to name if shortName is undefined
                "</div>" +
                "</div>"
            );
        },
    },
    items: defaultValue,
});

  }

  },
};
</script>

<style lang="scss">
@import "../scss/partials/variables.scss";
@import "../../node_modules/tom-select/dist/css/tom-select.css";
@import "../scss/partials/tom-select-overrides.scss";
</style>

<style lang="scss">
.quote-n-book-trigger-wrapper.sticky {
  display: none ! IMPORTANT;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
}

/* Dropdown Toggle */
.dropdown-toggle {
  text-decoration: none;
  padding: 5px 10px;
  color: #333;
  cursor: pointer;
}

/* Dropdown Menu */
.dropdown-menu {
  margin-top: 15px !important;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Dropdown Menu Items */
/* .dropdown-menu li {
  padding: 8px 12px; 
}
*/

.dropdown-menu li a {
  text-decoration: none;
  color: #333;
  display: block;
}

.dropdown-menu li a:hover {
  background-color: #1985a1;
  color: #fff !IMPORTANT;
}

/* Show the Dropdown Menu on Hover */
.dropdown:hover .dropdown-menu {
  display: block;
}

.quote-n-book-widget.expand {
  display: block ! IMPORTANT;
}

.header {
  background-image: url('../assets/bg-header.png'); /* Change to your image path */
  background-size: cover; /* Adjust background size */
  background-position: center; /* Center the background */
  background-repeat: no-repeat; /* Prevent background repetition */
}
.main-nav .main-links-nav {
  flex: 3 !important;
}

@media (max-width: 900px) {
  .main-nav .main-links-nav {
    display: none;
  }

}


.main-nav .main-links-nav a {
  font-size: 14px ;
  padding: 15px 10px !important;
  color: #1C244B;
  font-weight: 500;
}

ul.dropdown-menu a{
    font-size: 13px !important;
}
.main-nav .main-links {
  flex: 2 !important;
}
.main-nav .main-links a {
  padding: 15px 16px !important;
  color: #4a4a4a !important;
  
}

a.header-quote {
  line-height: 2px;
  /*background-image: linear-gradient(100deg, #1985A1 0%, #024455 100%);*/
  border: 1px solid #1985A1;
  padding: 20px !important;
  border-radius: 5px;
  color: #fff;
  line-height: 2px;
}

a.header-quote:hover {
  line-height: 2px;
  background-image: linear-gradient(100deg, #1985A1 0%, #024455 100%);
  padding: 20px !important;
  border-radius: 10px;
  color: #fff !important;
  line-height: 2px;
}

a.btn.header-right-button {
  border: 1px solid #1985A1;
  padding: 7px 15px !important;
  border-radius: 10px;
  font-size: 13px;
}

a.btn.header-right-button:hover {
  background-image: linear-gradient(100deg, #1985A1 0%, #024455 100%);
  padding: 7px 15px !important;
  color: #fff !important;
  border-radius: 10px;
  font-size: 13px;
}

.account {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}
button.btn.btn-dark {
  width: 30% !important;
  height: 40px !important;
  font-size: 11px !important;
  box-shadow: 0 2px 30px 0 rgba(231, 231, 231, 0.61) !important;
  font-weight: 400;
}

.btn-dark {
  color: #fff;
  background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
  border-color: #343a40; /* You can adjust this or remove it if you want to match the gradient */
}

a.btn.btn-dark {
  width: 42% !important;
  height: 39px !important;
  font-size: 11px !important;
  margin-right: 10px;
  box-shadow: 0 2px 30px 0 rgba(231, 231, 231, 0.61) !important;
  font-weight: 400;
  line-height: 23px;
}
a.btn.btn-outline-dark {
  height: 38px !important;
  font-size: 11px !important;
  width: 50% !important;
  line-height: 26px !important;
}

@media (max-width: 767px) {
  button.btn.btn-dark{
    width: 100% !important;
  }
}

/*.ts-control.single.input-hidden {
  // width: 176px;
}
*/
.ts-control.single {
  width: 100%;
  // width: 176px !important;
}

.main-nav .account .trigger {
  background-image: url('../assets/account-hamburger2.png') !important;
  background-position: top 31px right 16px !important;
}

.main-nav .account .dropdown .inner-content {
  background-color: #F4F4F4 !important;
  padding: 0px 0px !important;
}

.main-nav .account .dropdown a {
  color: #151425 !important;
}

.main-nav .account .dropdown a {
  padding: 12px 12px 12px 12px !important;
}

.main-nav .account .dropdown {
  right: 75px !important;
}

a.header-nav:hover {
  background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%);
  color: #fff !important;
}

.main-nav .account .dropdown a:hover {
  /*background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%);*/
  background-color: #1985a1;
  color: #fff !important;
  background-position: left 0px center;
}

.main-nav .account .dropdown a {
  font-size: 13px !important;
  padding: 14px 40px 14px 40px !important;
}

.container.xl {
  max-width: 1265px !important;
}
  

.dropdown-toggle::after{
  border-top: .35em solid;
  border-right: .35em solid transparent;
  border-bottom: 0;
  border-left: .35em solid transparent;
  margin-left: 14px;
}


.main-nav .logo{
  max-width: 150px !important;
}
</style>